.navbar {

  .navbar-logo {
    margin-top: 8px;
    justify-self: center;
    img {
      height: 24px;;
    }
  }

  background-color: white !important; 
  height: 60px;
  align-items: center;
  line-height: 38px;
  margin-top: 0;
  
  .bp3-navbar-group {
    height: 38px;
    line-height: 36px;
  }

  .bp3-input {
    font-size: 14px;
  }

  .bp3-button {
    height: 36px;
    margin: 0px;
    white-space: nowrap;
    
    .bp3-button-text {
      color: $dark-gray5;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.navbar-title {
  font-size: 16px;
  font-weight: bold;
  color: $turquoise2;
  text-transform: uppercase;;
}
