.custom-tabs {
  backdrop-filter: blur(24px);
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  &.no-top-margin {
    margin-top: 0;
  }

  .bp3-tab-panel {
    margin-top: 0;
    flex: 1;
  }

  .bp3-tab-list {
    border-bottom: 0;

    &:first-child {
      margin-left: 24px;
    }

    .bp3-tab {
      padding: 6px 12px; 
      margin: 0;
      border-right: 1px solid darken( #245278, 3%) !important;
      outline: none;
      font-size: 14px;
      font-weight: bold;
      // background-color: $light-gray1 !important;
      background-color: darken( #245278, 3%) !important;
      color: rgba(white, 0.5);
      &[aria-selected=true] {
        background-color: rgba(darken(#245278, 5%), 0.7) !important;
        color: white;
        border-bottom: none;
      }
      
    }
  }
}

.bp3-button.tab-button {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
  backdrop-filter: blur(24px);
  background: rgba(darken(#245278, 5%), 0.7) !important;
  color: rgba(white, 0.7);
  outline: none;
  font-size: 14px;
  font-weight: bold;

  &:hover {
    background-color: $turquoise1;
  }

  &.active {
    background: rgba(darken(#245278, 5%), 0.5) !important;
    color: white;
  }
}