.patterns-toolbar {
  align-items: center;
  border-color: darken( #245278, 5%);
  color: white;
  background-color: rgba(darken(#245278, 5%), 0.7);
  height: 48px;

  .bp3-icon {
    color: rgba(white, 0.7);
  }

  .bp3-button {
    color: white;

    &.bp3-disabled {
      opacity: 0.5;
    }
    
    .bp3-button-text {
      font-size: 14px;
      color: white;
    }
  }

  .bp3-input {
    background-color: transparent;
  }

  .bp3-form-group {
    height: 30px;
    margin-bottom: 0;
  }
}

