
.background {
  background-color: black;
  width: 100vw ;
  height: 100vh;
  top: 0x;
  left: 0px;
  overflow: hidden;
  position: absolute;
  z-index: 1;
}

.background-video {
  background-color: black;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  z-index: 2;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  transition: ease-in-out 1s all;

  &.blurred {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
}

.foreground {
  width: calc(100vw);
  height: calc(100vh);
  position: absolute;
  z-index: 10;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  &.blurred {
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    border-radius: 0;
  }

  .login-window {
    width: 396px;
    padding: 24px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 12px;

    .logo {
      height: 54px;
      margin: 24px auto 48px auto;
    }

    .bp3-input {
      color: white;
    }
    .bp3-label {
      width: 130px;
      text-align: left;
      font-weight: bold;
      opacity: 0.5;
    }
  }

  .bp3-button.login-btn {
    background: #245278 !important;
    color: white;
  }

  .bp3-button.password-reset-btn {
    color: white !important;

    &.bp3-disabled {
      color: $gray3 !important;
    }
  }

  .lang-select {
    cursor: pointer;
    margin: 0 6px;
    font-weight: bold;
    color: $gray3;

    &.active {
      color: white;
    }
  }

  .login-error {
    color: $red5;
    justify-content: center;
    font-weight: bold;
  }

  .lang-select-spacer {
    color: $gray3;
  }
}