.patterns-sidebar {
  flex: unset;
  color: white;
  width: 280px;
  height: calc(100vh - 60px);
  overflow-y: auto;
  overflow-x: hidden;
  background: #001D3A;
  background: linear-gradient(180deg, #000A1B, #001D3A);
  border-right: 1px solid $dark-gray2;
  font-size: 14px;

  a.bp3-menu-item {
    font-weight: bold;
    color: $light-gray5;
    outline: none;
  }

  .bp3-heading {
    color: $gray3;
  }

  .bp3-menu {
    .bp3-menu-item {
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 500;
      line-height: 30px;
      align-items: center;
      padding-top: 0px;
      padding-bottom: 0px;
      outline-width: 0;

      &:focus {
        outline-width: 0;
      }
    }

    .bp3-collapse {
      .bp3-menu-item {
        font-weight: 500;
      }

    }

    .bp3-menu-item.bp3-active.bp3-intent-primary.bp3-popover-dismiss {
      background-color: #0F5B7E;
      color: white;
    }

    .bp3-menu-header .bp3-heading {
      opacity: 0.7;
    }

    .bp3-icon {
      color: rgba(255, 255, 255, 0.7);
    }
  }
}

.sidebar-widget-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  margin: 1px;

  .sidebar-widget-box-inner {
    background-color: rgba(0, 0, 0, 0.3);
    margin: 6px 3px;
    display: flex;
    flex-direction: column;
    padding: 12px;
    text-align: center;
    font-size: 17px;
    width: 100%;
  }

  .subtitle {
    font-size: 14px;
    font-weight: 600;
    opacity: 0.7;
  }
}

.bp3-menu-item .bp3-icon {
  transform: scale(0.80) translateY(-2px);
}